
<template>
  <h1>Keyword Clustering</h1>
  <p>1. Trage bitte die mit Komma gretennten Keywords ein.</p>
  <div id="input" @click="focusInput">
    <span v-for="(keyword, index) in keywords" :key="keyword" class="keyword">
      {{ keyword }}
      <span class="removeKeyword" @click="removeKeyword(index)">x</span>
    </span>
    <input
      type="text"
      placeholder="Keyword mit Enter bestätigen"
      @keydown="addKeyword"
      ref="inputKeywords"
    />
  </div>

  <div class="csvimport">
    <span>Oder Import via CSV Datei: </span>
    <input type="file" accept=".csv" @change="handleFileUpload($event)" />
  </div>

  <CountrySwitch v-model:country="country" v-model:language="language" />
  <div>
    <button @click="clickCreateCluster">2. Cluster bilden</button>
  </div>
  <p class="msg">{{ msg }}</p>
  <div class="spinner" v-if="loading"><p>Clustering läuft...</p></div>
  <div class="results" v-else>
    <div v-for="cluster in clusters" :key="cluster.searchquery">
      <Card :cluster="cluster" />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/config.js";
import Card from "../components/Card.vue";
import CountrySwitch from "../components/CountrySwitch/CountrySwitch.vue";
import Papa from "papaparse";

const clusters = ref();
const loading = ref();
const msg = ref();
const keywords = ref([]);
const inputKeywords = ref();
const country = ref();
const language = ref();

const clickCreateCluster = async () => {
  if (keywords.value) {
    msg.value = "";
    const createCluster = httpsCallable(functions, "mainClustering");
    loading.value = true;
    const finalCluster = await createCluster({
      keywords: keywords.value,
      country: country.value,
      language: language.value,
    });
    loading.value = false;
    clusters.value = finalCluster.data;
  } else msg.value = "Bitte gegen ein Keyword ein!";
};

const addKeyword = (event) => {
  if (event.code == "Enter") {
    event.preventDefault();
    let val = event.target.value.trim();

    if (val.length > 0) {
      keywords.value.push(val);
      event.target.value = "";
    }
  }
};
const removeKeyword = (index) => {
  keywords.value.splice(index, 1);
};
const focusInput = () => {
  inputKeywords.value.focus();
};

const handleFileUpload = (event) => {
  let file = event.target.files[0];

  Papa.parse(file, {
    header: false,
    complete: function (results) {
      results.data.forEach((keyword) => {
        keywords.value.push(keyword[0]);
      });
      console.log(results.data);
    },
  });
};
</script>

<style>
button {
  padding: 15px;
  margin-top: 30px;
  background-color: #ffffff;
  cursor: pointer;
  border: 2px solid #42b883;
  color: #42b883;
  border-radius: 15px;
  font-size: 18px;
}
button:hover {
  background-color: #42b883;
  color: #ffffff;
}
button:active {
  font-size: 15px;
}
.results {
  margin: 30px 0;
  text-align: left;
}
.csvimport {
  text-align: left;
  margin: 15px 0;
  font-size: 13px;
}
.csvimport input {
  font-family: inherit;
  font-size: 13px;
}
#input {
  width: 100%;
  height: 350px;
  padding: 10px 15px 15px;
  margin: 0 auto;
  border: 2px solid #cccccc;
  border-radius: 15px;
  text-align: left;
}
#input input {
  width: 220px;
  height: 32px;
  margin-top: 5px;
  padding: 0;
  background: transparent;
  border: none;
  font-size: inherit;
  outline: none;
}
span.keyword {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px 5px 0 0;
  border-radius: 15px;
  background-color: #e7e7e7;
}
span.removeKeyword {
  color: #606060;
  font-weight: 800;
  cursor: pointer;
}
</style>