import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvHujS4vhonUUj91lAgMW7ae_x51sQQJI",
  authDomain: "keyword-clustering-4fef3.firebaseapp.com",
  projectId: "keyword-clustering-4fef3",
  storageBucket: "keyword-clustering-4fef3.appspot.com",
  messagingSenderId: "349239366298",
  appId: "1:349239366298:web:89dca95d991bb6d3270523"
};

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
//connectFirestoreEmulator(db, 'localhost', 8081);

const functions = getFunctions()
//connectFunctionsEmulator(functions, "localhost", 5001);

export { db, functions}